import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Taglist from "../components/taglist"
import "./blog-post.scss"

const BlogPost = ({ data }) => {
  const { frontmatter, html, fields } = data.markdownRemark
  return (
    <Layout>
      <SEO
        pathname={`/${fields.slug}`}
        title={frontmatter.title}
        lang="en"
        description={frontmatter.title}
      />
      <div style={{ margin: "0rem auto", maxWidth: "700px" }}>
        <h1>
          <strong>{frontmatter.title}</strong>
        </h1>
        <p>{frontmatter.date}</p>
        <Taglist tags={frontmatter.tags} />
        <div
          className="blog-post"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        tags
      }
      html
      fields {
        slug
      }
    }
  }
`
